<template>
  <filtro-rapido v-model="abrirFiltro"
                 ref="filtrosRapidos"
                 :campos-formulario="camposFormulario"
                 :metadados="getAcaoMetadado"
                 :ordenacao-campos="ordenacaoCampos"
                 :ignora-inativos="false"
                 :entidade-workspace="entidadeWorkspace"
                 @FiltroRapido__AplicaFiltros="(p) => $emit('LiquidacaoFiltro__AplicaFiltros', p)"
                 @FiltroRapido__HabilitaIconBadge="(value) => $emit('FiltroRapido__HabilitaIconBadge', value)"></filtro-rapido>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FiltroRapido from '../../shared-components/filtro-rapido/FiltroRapido';
import { buscarProdutosSelecao } from '../../common/resources/produto';
import { buscarHoldings } from '../../common/resources/planejamento/planejamento-acao-cadastro';

export default {
  name: 'LiquidacaoFiltro',
  props: {
    value: false,
    entidadeWorkspace: null,
  },
  components: {
    FiltroRapido,
  },
  data() {
    return {
      metadadoResource: this.$api.metadado(this.$resource),
      configuracaoResource: this.$api.liquidacaoConfiguracao(this.$resource),
      tipoAcaoResource: this.$api.tipoAcao(this.$resource),
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      produtoResource: this.$api.produto(this.$resource),
      divisaoResource: this.$api.divisao(this.$resource),

      abrirFiltro: false,
      metadadosPlanejamentoAcao: null,
      ordenacaoCampos: null,

      camposFormulario: {
        padrao: [
          {
            labelCampo: 'tipo_acao',
            nomCampoId: 'id_acao_tipo',
            tipoCampo: 'LISTA',
            async: {
              fetchFunction: (autocomplete) => this.tipoAcaoResource.buscarTodosTiposAcao({ somenteAcoesAvulsas: true, autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'status',
            nomCampoId: 'status',
            tipoCampo: 'LISTA_CHECKBOX',
            desAtributos: {
              textoItem: 'texto',
              valorItem: 'valor',
              lista: [
                {
                  texto: this.$t('status_entidade_pagamento.aguardando_pagamento'),
                  valor: 'AGUARDANDO_PAGAMENTO',
                },
                {
                  texto: this.$tc('status_entidade_pagamento.aprovado', 1),
                  valor: 'APROVADO',
                },
                {
                  texto: this.$tc('status_entidade_pagamento.programado', 1),
                  valor: 'PROGRAMADO',
                },
                {
                  texto: this.$tc('status_entidade_pagamento.liquidado', 1),
                  valor: 'LIQUIDADO',
                },
                {
                  texto: this.$t('status_entidade_pagamento.bloqueado'),
                  valor: 'BLOQUEADO',
                },
                {
                  texto: this.$t('status_entidade_pagamento.cancelado'),
                  valor: 'CANCELADO',
                },
              ],
            },
          },
          {
            labelCampo: 'data_inicio',
            tipoCampo: 'DATA',
          },
          {
            labelCampo: 'data_fim',
            tipoCampo: 'DATA',
          },
          {
            labelCampo: 'cod_acao',
            tipoCampo: 'NUMERICO',
          },
          {
            labelCampo: 'id_externo_pagamento',
            tipoCampo: 'TEXTO',
          },
          {
            labelCampo: 'unidadenegocio',
            nomCampoId: 'id_unidadenegocio',
            tipoCampo: 'LISTA_CHECKBOX',
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoResource
                .buscarUnidadesNegocioPorVisao({ autocomplete }),
              itemValue: 'id',
              itemText: 'nomExtensao',
            },
          },
          {
            labelCampo: 'cod_acao',
            tipoCampo: 'NUMERICO',
          },
          {
            labelCampo: 'produto',
            nomCampoId: 'id_produto',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => {
                const parametros = {
                  autocomplete,
                  // Filtro utilizado para filtrar a combo de Produtos por UN do usuário logado
                  filtrarPorExtensao: true,
                  ...this.$refs.filtrosRapidos.getValoresDependencias(),
                };
                return buscarProdutosSelecao(parametros, this.$resource);
              },
              itemValue: 'id',
              itemText: 'codigoNome',
            },
          },
          {
            labelCampo: 'holding',
            nomCampoId: 'id_holding',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => {
                const parametros = {
                  autocomplete,
                  ...this.$refs.filtrosRapidos.getValoresDependencias(),
                };
                return buscarHoldings(parametros, this.$resource);
              },
              itemValue: 'id',
              itemText: 'nomExtensao',
            },
          },
          {
            labelCampo: 'divisao',
            nomCampoId: 'id_divisao',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoResource
                .buscarDivisoesPorVisao({ autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters('metadados', [
      'getAcaoMetadado',
    ]),
  },
  methods: {
    ...mapActions('metadados', [
      'setAcaoMetadado',
    ]),
    carregaCamposFiltros() {
      this.configuracaoResource
        .buscarCamposFiltro({ tipo: 'ACAO' })
        .then((res) => {
          this.ordenacaoCampos = res.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
  },
  watch: {
    value(val) {
      this.abrirFiltro = val;
    },
    abrirFiltro(val) {
      this.$emit('input', val);
    },
  },
  mounted() {
    this.carregaCamposFiltros();
  },
  beforeMount() {
    this.setAcaoMetadado({
      resource: this.metadadoResource.definicaoAcao,
    });
  },
};
</script>
